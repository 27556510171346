<template>
  <div>
    <!-- <v-container v-if="getProposals('header-banner').length > 0">
      <ProposalSlider :proposals="getProposals('header-banner')" />
    </v-container> -->
    <component :is="templateName" :key="key"></component>
    <v-container v-if="getProposals('footer-banner').length > 0">
      <ProposalSlider :proposals="getProposals('footer-banner')" />
    </v-container>
  </div>
</template>
<style scoped lang="scss"></style>
<script>
// @ is an alias to /src
import categoryMixins from "~/mixins/category";
import deliveryReactive from "~/mixins/deliveryReactive";
import categoryMain from "@/components/category/CategoryMain";
import categoryMed from "@/components/category/CategoryMed";
import categoryLeaflet from "@/components/category/CategoryLeaflet";
import categoryCustom from "@/components/category/CategoryCustom";
import ProposalSlider from "@/components/proposal/ProposalSlider";

import split from "lodash/split";
import camelCase from "lodash/camelCase";
export default {
  name: "Category",
  mixins: [categoryMixins, deliveryReactive],
  components: {
    categoryMain,
    categoryMed,
    categoryLeaflet,
    categoryCustom,
    ProposalSlider
  },
  data() {
    return {
      key: 1
    };
  },
  computed: {
    templateName() {
      if (this.category.navigationTypeId == 1) {
        return "categoryLeaflet";
      } else if (this.category.template) {
        let templateName = camelCase(this.category.template);
        return templateName;
      } else {
        return "categoryMed";
      }
    }
  },
  methods: {
    async reload() {
      let slug = split(this.category.slug, "@")[0];
      await this.resetFilters(slug);
      await this.setCategory({ slug: slug, force: true });
      this.key += 1;
    }
  },
  async created() {
    await this.resetFilters(this.category.slug);
  },
  watch: {
    async $route(to) {
      let slug = to.params.pathMatch;
      await this.resetFilters(slug);
      await this.setCategory({ slug });

      //this.key += 1;
    }
  }
};
</script>
