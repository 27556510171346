import { render, staticRenderFns } from "./CategoryMainSlider.vue?vue&type=template&id=0461b941&scoped=true&"
import script from "./CategoryMainSlider.vue?vue&type=script&lang=js&"
export * from "./CategoryMainSlider.vue?vue&type=script&lang=js&"
import style0 from "./CategoryMainSlider.vue?vue&type=style&index=0&id=0461b941&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0461b941",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VCard,VCardTitle,VContainer,VImg})
