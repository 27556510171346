<template>
  <div class="products category-custom">
    <v-container v-if="getProposals('header-banner').length > 0">
      <ProposalSlider :proposals="getProposals('header-banner')" />
    </v-container>

    <v-container v-if="getProposals('header-products').length > 0">
      <ProductListSlider
        v-for="proposal in getProposals('header-products')"
        class="py-2 force-mobile-product-card"
        :key="proposal.id"
        :proposal="proposal"
        :title="proposal.descr"
        :paginationClass="'swiper-pagination' + proposal.id"
      />
    </v-container>

    <CategoryTitle :category="category" :count="count" :showOnMobile="true" />
    <ProductListGrid
      :parentCategoryId="category.categoryId"
      :key="category.categoryId"
      @productsCount="updateCount"
    />
  </div>
</template>
<script>
import ProductListSlider from "@/components/product/ProductListSlider.vue";
import ProposalSlider from "@/components/proposal/ProposalSlider.vue";
import ProductListGrid from "@/components/product/ProductListGrid.vue";
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import categoryMixins from "~/mixins/category";

export default {
  name: "CategoryCustom",
  mixins: [categoryMixins],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ProductListGrid,
    CategoryTitle,
    ProposalSlider,
    ProductListSlider
  },
  data() {
    return { count: -1 };
  },
  methods: {
    updateCount(count) {
      this.count = count;
    }
  }
};
</script>
