<template>
  <v-container class="mb-4 pl-0">
    <swiper :options="swiperOption" ref="swiperRef">
      <swiper-slide
        v-for="subCategory in categories"
        :key="subCategory.categoryId"
      >
        <v-card
          elevation="2"
          :to="{ name: 'Category', params: { pathMatch: subCategory.slug } }"
        >
          <v-img
            :src="subCategory.metaData.category_info.ICON"
            :alt="subCategory.name"
            max-width="170"
            max-height="170"
            class="center-img"
          ></v-img>
          <v-card-title>{{ subCategory.name }}</v-card-title>
        </v-card>
      </swiper-slide>
    </swiper>
    <div
      v-if="categories.length > 1"
      class="swiper-pagination swiper-pagination-firstCategory"
      slot="pagination"
    ></div>
  </v-container>
</template>

<style lang="scss" scoped>
.swiper-container {
  height: 255px;
}
.swiper-slide {
  width: initial;
}
.swiper-pagination-firstCategory {
  bottom: -20px;
  display: flex;
  /* flex-direction: column; */
  position: relative;
  justify-content: center;
}

.v-card {
  width: 250px;
  height: 250px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  .v-card__title {
    color: $primary;
    font-weight: 600;
    font-size: 20px;
    line-height: 1;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
    word-break: break-word;
  }
}
</style>

<script>
export default {
  name: "CategoryMainSlider",
  props: {
    categories: { type: Array, required: true }
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: "auto",
        spaceBetween: 20,
        watchOverflow: true,
        pagination: {
          el: ".swiper-pagination-firstCategory",
          clickable: true
        }
      }
    };
  }
};
</script>
