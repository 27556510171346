<template>
  <div class="products category-med">
    <Breadcrumb :items="breadcrumb" />
    <v-container v-if="getProposals('header-banner').length > 0">
      <ProposalSlider :proposals="getProposals('header-banner')" />
    </v-container>
    <v-container v-if="getProposals('header-products').length > 0">
      <ProductListSlider
        v-for="proposal in getProposals('header-products')"
        class="py-2 force-mobile-product-card"
        :key="proposal.id"
        :proposal="proposal"
        :title="proposal.descr"
        :paginationClass="'swiper-pagination' + proposal.id"
      />
    </v-container>
    <CategoryTitle :category="category" :count="count" height="107px" />
    <ProductListGrid
      v-bind:parentCategoryId="category.categoryId"
      :key="category.categoryId"
      @productsCount="updateCount"
    />
  </div>
</template>
<style scoped lang="scss"></style>
<script>
import ProposalSlider from "@/components/proposal/ProposalSlider.vue";
import ProductListSlider from "@/components/product/ProductListSlider.vue";
import ProductListGrid from "@/components/product/ProductListGrid.vue";
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import Breadcrumb from "@/components/navigation/Breadcrumb";
import categoryMixins from "~/mixins/category";
import get from "lodash/get";
export default {
  name: "CategoryMed",
  mixins: [categoryMixins],
  components: {
    ProductListGrid,
    CategoryTitle,
    Breadcrumb,
    ProductListSlider,
    ProposalSlider
  },
  data() {
    return { count: -1 };
  },
  methods: {
    updateCount(count) {
      this.count = count;
    }
  },
  metaInfo() {
    return {
      title: get(
        this.category,
        "metaData.category_seo.SEO_TITLE",
        this.category ? this.category.name : ""
      ),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: get(
            this.category,
            "metaData.category_seo.SEO_DESCRIPTION",
            this.$t("meta.category.secondAndThirdLevel.description", [
              this.category.name,
              this.breadcrumb[this.breadcrumb.length - 2]?.text
            ])
          )
        }
      ]
    };
  }
};
</script>
