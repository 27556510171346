<template>
  <div class="productscategory-main">
    <!-- v-if="!$vuetify.breakpoint.smAndDown" -->
    <Breadcrumb :items="breadcrumb" />

    <v-container v-if="getProposals('header-banner').length > 0">
      <ProposalSlider :proposals="getProposals('header-banner')" />
    </v-container>

    <v-container v-if="getProposals('header-products').length > 0">
      <ProductListSlider
        v-for="proposal in getProposals('header-products')"
        class="py-2 force-mobile-product-card"
        :key="proposal.id"
        :proposal="proposal"
        :title="proposal.descr"
        :paginationClass="'swiper-pagination' + proposal.id"
      />
    </v-container>

    <CategoryTitle :category="category" height="107px" />
    <v-container>
      <ProposalSlider :proposals="getProposals('header-banner')" />

      <CategoryMainSlider :categories="category.children" />

      <ProductListSlider
        :layout="25"
        :categoryId="category.categoryId"
        :promo="true"
        :title="'In promozione in ' + category.name"
        :paginationClass="'swiper-pagination-promo'"
      />
      <ProductListSlider
        :layout="27"
        :categoryId="category.categoryId"
        :title="'I più venduti in ' + category.name"
        :paginationClass="'swiper-pagination-venduti'"
      />
    </v-container>
  </div>
</template>
<style scoped lang="scss">
.center-img {
  margin-right: auto;
  margin-left: auto;
}
</style>
<script>
// @ is an alias to /src
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import ProductListSlider from "@/components/product/ProductListSlider.vue";
import CategoryMainSlider from "@/components/category/CategoryMainSlider.vue";
import ProposalSlider from "@/components/proposal/ProposalSlider.vue";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";

import categoryMixins from "~/mixins/category";
import get from "lodash/get";
export default {
  name: "CategoryMain",
  mixins: [categoryMixins],
  data() {
    return {
      swiperOption: {
        slidesPerView: "auto",
        spaceBetween: 20,
        watchOverflow: true,
        pagination: {
          el: ".firstCategory",

          clickable: true
        }
      }
    };
  },
  components: {
    CategoryTitle,
    ProductListSlider,
    CategoryMainSlider,
    ProposalSlider,
    Breadcrumb
  },
  metaInfo() {
    return {
      title: get(
        this.category,
        "metaData.category_seo.SEO_TITLE",
        this.category ? this.category.name : ""
      ),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: get(
            this.category,
            "metaData.category_seo.SEO_DESCRIPTION",
            this.$t("meta.category.firstLevel.description", [
              this.category.name
            ])
          )
        }
      ]
    };
  }
};
</script>
